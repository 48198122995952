<template>
  <div>
    <van-list>
      <van-cell-group>
        <van-field required v-model="Username" label="姓名" placeholder="请输入您的姓名" left-icon="user-o"/>
        <van-field required v-model="Managecard" label="证件号" maxlength="18" placeholder="请输入您的身份证或护照号" left-icon="idcard"/>
        <van-field required v-model="Sex" label="性别" placeholder="请输入您的性别" left-icon="friends-o"/>
         <van-field required v-model="nation" label="民族" placeholder="请输入您的民族" left-icon="idcard"/>
     
        <van-field required v-model="TelNumber" label="手机号" placeholder="请输入您的手机号" type="number" left-icon="phone-o"/>
        <van-field required v-model="Birthday" readonly label="出生日期" placeholder="请输入您的出生日期" left-icon="calender-o" @click="showPopup"/>
        <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
          <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" @cancel="show = false" :formatter="formatter" @confirm="onConfirm"/>
        </van-popup>
        <van-field required v-model="FamilyAddress" label="家庭住址" placeholder="请输入您的家庭住址" left-icon="home-o"/>
      </van-cell-group>
    </van-list>
    <div class="Addcard_hint" >*温馨提示：请认真填写信息，绑定的信息将作为就诊凭证</div>
    <van-checkbox v-model="checked" checked-color="#57c4b7" shape="square" style="margin:8% 17%"> <a @click="popupShow=true" style="color:#ABABAB">阅读并同意《用户协议》</a></van-checkbox>
     <van-popup v-model="popupShow" :style="{ height: '100%', width: '100%' }">
        <div>
          <van-nav-bar title="福贡县人民医院用户协议书"/>
          <!-- <van-nav-bar title="澜沧县人民医院用户协议书" /> -->
          <van-panel
            title="(一)服务条款确认和接纳"
            desc='1、本系统按照本协议的规定及不定期发布的操作规则提供基于互联网的医疗服务（以下称"互联网医疗服务"）。为获得互联网医疗服务，服务使用人（以下称"用户"）应当同意本协议的全部条款并按照页面上的提示完成注册程序。用户在进行注册程序过程中点击“我同意”按钮即表示完全接受本协议项下的全部条款。
2、用户点击同意本协议的，即视为用户确认自己具有享受本客户端服务、下单购买等相应的权利能力和行为能力，能够独立承担法律责任。
3、限制行为能力人和无民事行为能力人，需要在监护人的监护参与下才能使用本系统。
4、医疗服务的具体内容由平台根据实际情况提供，平台保留随时变更、中断或终止部分或全部互联网医疗服务的权利。'
          >
          </van-panel>
          <van-panel
            title="（二）版权说明"
            desc="1、本系统提供的相关服务内容可能包括：文字、软件、声音、图片、视频、录象、图表等。所有这些内容受版权、商标和其它财产所有权法律的保护。
2、本系统所登载的专有信息，未经本系统或其他相关权利人的书面授权，任何人不得以任何形式侵犯其著作权，包括擅自复制、非法使用或转载。"
          ></van-panel>
          <van-panel
            title="（三）服务权责"
            desc="1、云南拓实科技有限公司负责本系统正常运行，将协助医生或顾问与用户之间进行合法的交流，并提供必要的网络技术支持，确保提供优质的健康咨询服务通道。
2、本系统有权对相关数据、所有的申请行为以及与健康信息互通交流有关的其它事项进行审核，并在不符合平台要求的情况下给予拒绝或屏蔽。
3、用户在本系统发生不当行为，经本系统核实认为应当终止服务的，本系统有权随时删除该用户信息及终止服务，且无需征得用户的同意。
"
          ></van-panel>
          <van-panel
            title="（四）免责声明"
            desc="1、本系统所有咨询、回答、相关评论仅代表作者本人的观点或基本判断，不代表本系统的观点和看法，与本系统立场无关。
2、本系统所刊载的各类形式(包括但不仅限于文字、图片、图表)的作品（包括广告等）仅供参考使用，并不代表本系统同意其说法或描述，也不构成任何方面建议。对于用户根据本平台提供的信息所做出的一切行为，除非另有明确的书面承诺文件，否则本系统不承担任何形式的责任。
3、凡在本系统登记注册的用户，须自行负责对您的账户和密码保密，且须对您的账户和密码下发生的所有活动承担全部责任。
4、用户自愿同意不做任何妨碍或者破坏服务（或与服务连接的服务器及网络）的任何活动。若因散布谣言、造假、谩骂等原因而被禁止本系统对其服务的，本系统均免责。
5、当政府部门、司法机关等依照法定程序要求本系统披露个人资料时，本系统将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本系统均免责。
6、由于与本系统链接的其他软件或网页所造成的个人资料泄露及由此而导致的任何法律争议和后果，本系统均免责。
7、由于用户将个人密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露，本系统均免责。
8、任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常运营的不可抗力造成的个人资料泄露、丢失、被盗用或被窜改等，本系统均免责。
9、除本系统注明的服务条款外，其他一切因使用本软件导致的任何意外、疏忽、诽谤、版权或知识产权侵犯及其所造成的损失(包括因下载而感染手机病毒)，本系统不承担任何法律责任。
10、用户自行同意本系统可自行更新、升级或关闭，而无需经过用户的同意。用户可以在任何时候停止使用本系统，而无需特别通知。
"
          ></van-panel>
          <van-panel
            title="（五）用户的权利和义务"
            desc="1、用户应自行诚信向本客户端提供注册资料，用户保证提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本系统保留终止用户使用平台各项服务的权利。
2、用户注册成功后，将产生用户名和密码等账户信息，您可以根据本系统规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本系统并向公安机关报案。
3、用户有权通过本系统发布、获取信息，进行健康信息互通交流等，本系统另一端的医生或顾问将完全根据用户提供的信息做出指导和建议，如果用户提供的信息有误，造成的任何后果自行承担。
4、用户不得利用本系统各项服务在本系统或相关网站上进行销售或其他商业行为。用户违反此约定，本系统将依法追究其法律责任，由此给本系统造成损失的，本系统有权进行追偿。
5、用户同意，本系统拥有通过邮件、短信电话等形式，向在本系统注册、购买的用户发送订单信息、促销活动等告知信息的权利。
6、用户不得将在本系统注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
7、用户明确同意：使用本系统相关服务所存在的风险将完全由用户自己承担；因其使用互联网医院服务而产生的一切后果也由自己承担，互联网医院对用户不承担任何责任。
8、用户同意，本系统有权使用用户的注册信息、用户名、密码等信息，登陆进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
9、用户在使用互联网医院相关服务过程中，必须遵循以下原则：
（1）遵守中国有关的法律和法规；
（2）不得为任何非法目的而使用互联网医院服务系统；
（3）遵守所有与互联网医院服务有关的网络协议、规定和程序；
（4）不得利用互联网医院服务系统进行任何可能对互联网的正常运转造成不利影响的行为；
（5）不得利用网络医院服务系统进行任何不利于医院的行为；
（6）如发现非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告平台系统管理员或者客服人员。
"
          ></van-panel>
          <van-panel
            title="（六）隐私"
            desc="1、保护用户隐私是互联网医院的一项基本政策，本系统保证不对外公开或向第三方提供用户注册资料及用户在使用互联网医院相关服务时存储的非公开内容，但下列情况除外：
（1）事先获得用户的明确授权；
（2）根据有关的法律法规要求；
（3）按照相关政府主管部门的要求；
（4）为维护社会公众的利益；
（5）为维护互联网医院的合法权益。
2、本系统可能会与第三方合作向用户提供相关的网络服务，如该第三方同意承担与医院同等的保护用户隐私的责任，则医院可将用户的注册资料（包括姓名、性别、个人有效身份证件号码、联系方式、家庭住址等）等提供给该第三方。
3、在不透露单个用户隐私资料的前提下，本系统有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
"
          ></van-panel>
          <van-panel
            title="（七）互联网医疗服务变更、中断或终止"
            desc="1、如因系统维护或升级的需要而需暂停互联网医疗服务，平台将尽可能事先进行通告。如发生下列任何一种情形，平台有权随时中断或终止向用户提供本协议项下相关的互联网医疗服务而无需通知用户：
（1）用户提供的个人资料不真实；
（2）用户违反本协议中规定的使用规则。
2、除前款所述情形外，系统因下列状况无法正常运作，使用户无法使用视频语音咨询服务时，平台不承担损害赔偿责任，该状况包括但不限于：
（1）互联网医院在本网站公告之系统停机维护期间；
（2）电信设备出现故障不能进行数据传输的；
（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的；
（4）由于黑客攻击、电信部门技术调整或故障、银行方面的问题等原因而造成的服务中断或者延迟。"
          ></van-panel>
          <van-button type="primary" block class="popBtn" @click="popupShow = false"
            >确定</van-button
          >
        </div>
      </van-popup>
     <div style="font-size:12px;color:blue;text-align:right;padding:0 20px 5px 20px;text-decoration:underline;" @click="binding" >已有健康卡，前往一键关联</div>
    <van-button round type="primary" size="large" style="width:90%;background:#57c4b7; margin:3% 5%;" :disabled="!checked" @click="onClickaddM"><span style="color:white;">注册健康卡</span></van-button>
    <van-popup :close-on-click-overlay="false"  closeable v-model="addressShow" :style="{height:'200px',width:'300px',padding:'10px'}">
               <div style="text-align:center;margin-top:5px;font-weight:bold;">一键绑定信息补全</div>
             <div style="margin-top:20px;">
               <span>家庭地址：</span> <input placeholder="请输入家庭地址" type="text" v-model="address" style="height:30px" >
             </div>
          <van-checkbox v-model="checked" checked-color="#57c4b7" shape="square" style="margin:6% 17%;"> <a @click="popupShow" style="color:#ABABAB;font-size:13px">阅读并同意《用户协议》</a></van-checkbox>
            <van-button :disabled="!checked" style="display:block;margin:20px auto;"  color="#57c4b7" type="primary" @click="bind">继续绑定</van-button>
          
     </van-popup>
     <van-overlay :show="showjoin"  style="text-align:center;line-height:500px"><span style="color:#57c4b7;font-size:18px;">加载中...</span> </van-overlay >
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>@拓实银医</span></div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import ajax from '../../lib/ajax';
// import store from '../../store';
const hospitalld="34742";
 

export default {
  data () {
    return {
      show:false,
       popupShow: false,
      minDate:new Date(1900, 0, 1),
      maxDate:new Date(),
      currentDate:new Date(),
      checked: false,
      index:0,
      Username:"",
      Sex:"",
      Birthday:"",
      FamilyAddress:"",
      Medicalcard:"",
      Managecard:"",
      TelNumber:"",
      qrCodeText:"",
      idCard:"",
      nation:"",
      wechatCode:"",
      HealthCode:"",
      addressShow:false,//一键绑定地址弹出层
      address:"",
      wxopenid:"",
      showjoin:false
      // PassWord:"jzgcXrFVOJw",
    }
  },
  mounted(){
       this.HealthCode = this.$route.query.healthCode;
   if(this.HealthCode){
        if(this.HealthCode==0){
                    this.$router.replace("/bind");
                  }else if(this.HealthCode==-1){
                     
                  wx.closeWindow();
                  }else{
                    this.wxopenid =  sessionStorage.getItem('openid'); 
                    this.inputaddress();
                  }
   }else{
      let wechatCode = this.$route.query.wechatCode;
     this.wechatCode=wechatCode;
     let redirect_uri="http://wx.fgxrmyy.cn/bind";
     if(!this.wechatCode){
     window.location.href=`https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirect_uri}`;
     }
     this.wxopenid =  sessionStorage.getItem('openid'); 
   }
    
  
  },
  methods: {
     formatDate(date) {
      let Month =date.getMonth() + 1;
      let Day=date.getDate();
      //console.log(`${date.getFullYear()}/${Month<=9?'0'+Month:Month}/${Day<=9?'0'+Day:Day}`)
       return `${date.getFullYear()}/${Month<=9?'0'+Month:Month}/${Day<=9?'0'+Day:Day}`;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`
      } else if(type === 'day'){
        return `${val}日`
      }
      return val;
    },
    showPopup() {
      this.show = true;
    },
    onConfirm(date) {
      this.show = false;
      this.Birthday = `${this.formatDate(date)}`;
    },
    onuseragree(){
      this.$router.replace({
        path:'UA',
      })
    },
    onEnter(){
      let me = this;
      let date = new Date();
      let sexs = me.Managecard.substring(16,17);
      let ages = me.Managecard.substring(6,10);
      let dates = ages + "-" + me.Managecard.substring(10,12) + "-" + me.Managecard.substring(12,14);
      me.Sex = sexs % 2 > 0 ? "男" : "女";
      me.Age = date.getFullYear() - ages;
      me.Birthday = dates;
    },
     onClickaddM(){
         this.showjoin=true;
      if(this.Username == ''||this.Username.length==0){
        Toast('姓名不能为空！');
        this.showjoin=false;
        return;
      }else if(this.Managecard==''||this.Managecard.length==0){
        Toast('证件号不能为空！');
        this.showjoin=false;
        return;
      }else if(this.Managecard.length!=9&&this.Managecard.length!=18){
        Toast('请核对证件号后再进行操作！');
        this.showjoin=false;
        return;
      }else if(this.Sex==''||this.Sex.length==0){
        Toast('性别不能为空！');
        this.showjoin=false;
        return;
      }else if(this.nation==''||this.nation.length==0){
        Toast('民族不能为空！');
        this.showjoin=false;
        return;
      }else if(this.TelNumber==''||this.TelNumber.length==0){
        Toast('手机号码不能为空！');
        this.showjoin=false;
        return;
      }else if(this.Birthday==''||this.Birthday.length==0){
        Toast('出生日期不能为空！');
        this.showjoin=false;
        return;
      }else if(this.TelNumber.length!=11){
        Toast('请核对手机号码后再进行操作！');
        this.showjoin=false;
        return;
      }else if(this.FamilyAddress==''||this.Birthday.length==0){
        Toast('家庭住址不能为空！');
        this.showjoin=false;
        return;
      }
      var me = this;
      let sex = me.Sex;
        let subData={
                          birthday:  this.Birthday,
                          idType: "01",
                          gender: this.Sex,
                          idNumber: this.Managecard,
                          name: this.Username,
                          nation: this.nation,
                          phone1:this.TelNumber,
                          wechatCode:this.wechatCode,
                  }
                //  console.log(subData);
                //  console.log(this.appToken)
                ajax.post("/Api/HealthCardService/RegisterHealthCard",subData).then((res)=>{
                console.log(res)
                  this.healthCardId=res.data.result.rsp.healthCardId;
                  this.qrCodeText=res.data.result.rsp.qrCodeText;
                   if(res.data.result.commonOut.errMsg=="成功"){
                         // ajax.get(`/Api/HealthCardService/BindCardRelation?Patid=530125200201171512&QrCodeText=189DA34C1BC5E55A2585DA0867AD17C6477F51BBC45A5940F75DEE9C83474A23:0:D660E98498E03EB4C4A4FDFAAD1EBF92:5300A0069FWHX0002&AppToken=F44AF8513DEAB893A6447ECE90299CB5`)
                        ajax.get(`/Api/HealthCardService/BindCardRelation?QrCodeText=${this.qrCodeText}&Patid=${this.idCard}`)
                        .then((res)=>{
                          console.log(res)
                          console.log("开始绑定了");
                          if(res.data.result.commonOut.errMsg=="成功"){
                             let c = me.Managecard;
                            let year = c.substring(6, 10);
                            let month = c.substring(10, 12);
                            let day = c.substring(12, 14);
                            let Birthday = year + "/" + month + "/" + day;
                                
                              ajax.post("/Api/CardService/WeChatRegistNoPay", {
                                          openID:this.wxopenid,
                                          orderId:0,
                                          barCode: this.healthCardId,
                                          // pwd:me.PassWord,
                                          operatorId:'0733',
                                          phone:me.TelNumber,
                                          sex:sex,
                                          birthday:Birthday,
                                          userName: me.Username,
                                          idCard:me.Managecard,
                                          familyAddress:me.FamilyAddress,
                                          machineName:'wechat',
                                        }).then((response)=>{
                                          var lat =response.data;
                                          console.log(JSON.stringify(lat));
                                          Toast("注册成功，正在登录！！！");
                                          this.$router.replace({
                                            path:'/healcard'
                                          }).catch(err => {
                                            console.log(err);
                                          });
                                        }).catch(()=>{
                                          Toast("服务器数据错误！");
                                        })
                              }
                            })
                      }else{
                        Toast.fail(res.data.result.commonOut.errMsg);
                        this.showjoin=false;
                      }
                }).catch(()=>{
                  Toast.fail("注册失败，请刷新重试！");
                  this.showjoin=false;
                })
     
    },
    binding(){
       let redirect_uri="http://wx.fgxrmyy.cn/bind";
     window.location.href=`https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirect_uri}&hospitalId=${hospitalld}`;
    },
     inputaddress(){
      this.addressShow=true;
    },
    bind(){
      this.showjoin=true;
      if(this.address==""){
                            Toast.fail("家庭地址不能为空！");
                            return;
                          }
      ajax.post("/Api/HealthCardService/GetHealthCardByHealthCode",{healthCode:this.HealthCode})
      .then((res)=>{
        // console.log(res)
       
        let card=res.data.result.rsp.card;
        if(!card){
           this.addressShow=false;
           this.showjoin=false;
            Toast("参数错误，请重试或手动注册！！！");
            

        }
         let c = card.idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let Birthday = year + "/" + month + "/" + day;
      ajax.get(`/Api/HealthCardService/BindCardRelation?QrCodeText=${card.qrCodeText}&Patid=${card.idCard}`)
                        .then((res)=>{
                          console.log(res)
                          console.log("开始绑定了");
                          if(res.data.result.commonOut.errMsg=="成功"){
                                        let sex = card.gender
                                
                              ajax.post("/Api/CardService/WeChatRegistNoPay", {
                                          openID:this.wxopenid,
                                          orderId:0,
                                          barCode:card.healthCardId,
                                          // pwd:me.PassWord,
                                          operatorId:'0733',
                                          phone:card.phone1,
                                          sex:sex,
                                          birthday:Birthday,
                                          userName: card.name,
                                          idCard:card.idCard,
                                          familyAddress:this.address,
                                          machineName:'wechat',
                                        }).then((response)=>{
                                          var lat =response.data;
                                          console.log(JSON.stringify(lat));
                                          Toast("注册成功，正在登录！！！");
                                          this.$router.replace({
                                            path:'/healcard'
                                          }).catch(err => {
                                            console.log(err);
                                          });
                                        })
                              }else{
                                Toast(res.data.result.commonOut.errMsg);
                                this.showjoin=false;
                              }
                            })
      }).catch((err)=>{
        Toast(this.HealthCode);
        this.showjoin=false;
        console.log(err)})
    },
  },
  watch:{
     Managecard(old){
       if(old.length==18){
          this.onEnter()
       }
     }
  }
}
</script>
<style lang="less" scoped>
.login_container {
  height: 100%;
}
  .addbac{
    background-color: #30B9A9;
    color: #fff;
  }
  .Addcard_header{
  width: 100%;
    height: 50px;
    line-height: 50px;
    border: 1px solid #f1f1f1;
    span {
      text-align: center;
      width: 50%;
      float: left;
    }
    .box_content {
    width: 100%;
  }


  }
  .list {
      height: 40px;
      width: 100%;
      line-height: 50px;
      border: 1px solid #f1f1f1;
      color: red;
      input {
        height: 40px;
        line-height: 50px;
        padding-left: 20px;
      }
    }
  .Addcard_hint{
      margin: 20px 10px 0px 5px;
      color: #57c4b7;
      font-size: 75%;
  }
  .Addcard_checkbox{
      margin: 50px;
  }
</style>
